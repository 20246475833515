@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

.card {
    width: 220px;
    height: 280px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    margin: 15px;
}

.cardDetails {
    color: rgb(162, 0, 255);
    height: 100%;
    gap: .5em;
    display: grid;
    place-content: center;
    font-family: Arial, sans-serif;
}

.cardButton {
    text-decoration: none;
    text-align: center;
    transform: translate(-50%, 125%);
    width: 70%;
    border-radius: 1rem;
    border: none;
    background-color: #6c00f8;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
    cursor: pointer;
    font-family: Arial, sans-serif;
}

.textBody {
    color: rgb(134, 134, 134);
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.textTitle {
    font-size: 1.5em;
    font-weight: bold;
}

.card:hover {
    border-color: #6c00f8;
    box-shadow: 10px 5px 18px 0 rgba(255, 255, 255, 0.877);
}

.card:hover .cardButton {
    transform: translate(-50%, 50%);
    opacity: 1;
}