.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.card {
  width: 100%;
  max-width: 500px;
  border-radius: 30px;
  background: #e0e0e0;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  padding: 20px;
  margin: 20px 0;
  box-sizing: border-box;
}

.content {
  padding: 10px;
}

.iconWrapper {
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
}

.price {
  color: white;
  font-weight: 500;
  font-size: 20px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.42);
}

.description {
  color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
  font-size: 14px;
}

.title {
  font-weight: 800;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  margin-top: 10px;
  font-size: 25px;
  letter-spacing: 1px;
  text-align: center;
}

.item {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.item .property {
  color: #0D9A5C;
}

.item .value {
  display: inline;
}

.item .negative {
  color: red;
}

.button {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  padding: 0.75rem 1.5rem;
  background-color: rgb(33, 150, 243);
  border-radius: 0.5rem;
  width: 100%;
  text-shadow: 0px 4px 18px #2c3442;
  margin-top: 20px;
}

.backButton {
  position: relative;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #05bcdc;
}

.backButton:hover {
  background-color: #e0e0e0;
}

.tradingViewButton {
  margin-top: 10px;
  background-color: #2962ff;
}

.outlink {
  font-size: 0.8em;
  vertical-align: super;
  margin-left: 5px;
}

.technicalAnalysis,
.fundamentals {
  width: 100%;
  max-width: 700px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.technicalAnalysis h2,
.fundamentals h2 {
  margin-bottom: 10px;
  text-align: center;
  color: black;
}

.tradingviewWidgetContainer {
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
}

@media (min-width: 769px) {
  .tradingviewWidgetContainer {
    max-width: 660px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  .card,
  .technicalAnalysis,
  .fundamentals {
    width: 100%;
    max-width: none;
    padding: 20px 10px;
  }

  .tradingviewWidgetContainer {
    max-width: 100%;
    padding: 0 10px;
  }
}