.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background: #146caa;
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

body {
    padding-top: 60px;
}

.logoLink {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logoImage {
    width: 3.5rem;
    height: auto;
    object-fit: contain;
}

.navContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.navLinks {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: center;
    flex-grow: 1;
}

.navLinks>li {
    margin: 0 10px;
}

.links {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 1rem;
}

.loginButton {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.9rem 2rem;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 700;
    font-size: 14px;
    border-radius: 400px;
    overflow: hidden;
    background: #66ff66;
    color: ghostwhite;
}

.loginButton span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
}

.loginButton:hover span {
    color: black;
}

.loginButton::before,
.loginButton::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.loginButton::before {
    content: "";
    background: #000;
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.loginButton:hover::before {
    transform: translate3d(100%, 0, 0);
}

.mobileNav {
    display: none;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.userImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.userDropdown {
    position: relative;
}

.dropdownContent {
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1001;
}

.dropdownLink {
    text-decoration: none;
    color: #333;
    padding: 5px 0;
}

.logoutButton {
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 5px 0;
    text-align: left;
}

@media (max-width: 768px) {
    .nav {
        height: 70px;
    }

    body {
        padding-top: 70px;
    }

    .mobileNav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
    }

    .hamburger {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .navContent {
        display: none;
        flex-direction: column;
        width: 100%;
        align-items: center;
        background: #146caa;
        position: fixed;
        top: 70px;
        left: 0;
        padding: 1rem 0;
        z-index: 999;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    .navLinks {
        width: 100%;
    }

    .userDropdown {
        display: none;
    }

    .navContent.active {
        display: flex;
    }

    .navLinks {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .navLinks>li {
        margin: 10px 0;
    }

    .loginButton {
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    .mobileOnly {
        display: block;
    }

    .desktopOnly {
        display: none;
    }
}

@media (min-width: 769px) {
    .mobileOnly {
        display: none;
    }

    .desktopOnly {
        display: block;
    }
}

.loginButtonLink {
    text-decoration: none;
}