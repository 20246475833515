@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.pageNumbersContainer {
    display: flex;
    gap: 10px;
}

.pageItem {
    margin: 0 2px;
}

.pageLink {
    font-weight: bold;
    background-color: #f0f0f0;
    color: black;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.pageLink.active {
    background-color: #19F5AA;
    color: white;
}

.pageLink.disabled {
    background-color: #f0f0f0;
    color: #ccc;
    cursor: not-allowed;
}

.button {
    margin: 0 5px;
    background-color: #f0f0f0;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.button.disabled {
    color: #ccc;
    cursor: not-allowed;
}

.orderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.card {
    width: 100%;
    min-height: 150px;
    border-radius: 15px;
    background: #e0e0e0;
    box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff;
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-align: left;
    color: black;
}

.card h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 1.1em;
}

.card p {
    margin: 4px 0;
    font-size: 0.85em;
}

.userInfo {
    width: 100%;
    background-color: #15d3c7;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.overviewTitle {
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2em;
    color: rgba(126, 20, 159, 0.543);
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.switchContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.switchButton {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    margin-top: 30px;
}

.switchButton.active {
    background-color: #007bff;
    color: white;
}

.emptyMessage {
    text-align: center;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin: 20px 0;
}

.emptyMessage p {
    font-size: 18px;
    color: #666;
}