.card {
    cursor: pointer;
    width: auto;
    min-width: 190px;
    height: auto;
    min-height: 254px;
    padding: 20px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, .2);
    transition: all .2s;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: visible;
}

.card:hover {
    box-shadow: -12px 12px 2px -1px rgba(0, 0, 255, .2);
}

.card p {
    font-weight: bold;
    color: black;
}

.infoIconWrapper {
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

.infoIcon {
    cursor: pointer;
    color: #007bff;
    font-size: 14px;
    font-weight: bold;
    width: 16px;
    height: 16px;
    border: 1px solid #007bff;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.tooltipText {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal;
    font-size: 14px;
}

@media (max-width: 768px) {
    .tooltipText {
        width: 160px;
        left: 100%;
        bottom: 50%;
        top: auto;
        transform: translateY(50%);
        margin-left: 10px;
    }

    .infoIconWrapper {
        position: relative;
    }
}


.infoIconWrapper:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

.inputContainer {
    position: relative;
    margin: 20px;
}

.inputField {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    background-color: transparent;
}

.inputLabel {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    color: rgba(204, 204, 204, 0);
    pointer-events: none;
    transition: all 0.3s ease;
}

.inputHighlight {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: #007bff;
    transition: all 0.3s ease;
}

.inputField:focus+.inputLabel {
    top: -20px;
    font-size: 12px;
    color: #007bff;
}

.inputField:focus+.inputLabel+.inputHighlight {
    width: 100%;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 4px;
    height: 32px;
    width: 81px;
    border: none;
    background: #1b1b1cd0;
    border-radius: 20px;
    cursor: pointer;
}

.lable {
    line-height: 22px;
    font-size: 17px;
    color: #DE8A2A;
    font-family: sans-serif;
    letter-spacing: 1px;
}

.button:hover {
    background: #1b1b1cea;
}

.button:hover .svgIcon {
    animation: rotate 1.3s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}