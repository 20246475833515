/* COMMON STYLES*/
.popup {
    position: fixed;
    top: 20px;
    right: 20px;
    margin: 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 300px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-radius: 4px;
    padding: 12px;
    font-weight: 400;
    z-index: 1000;
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.popup svg {
    width: 1.25rem;
    height: 1.25rem;
}

.popup-icon svg {
    margin: 5px;
    display: flex;
    align-items: center;
}

.close-icon {
    margin-left: auto;
}

.close-svg {
    cursor: pointer;
}

.close-path {
    fill: grey;
}

/*SEPERATE STYLES*/
/* SUCCESS */
.success-popup {
    background-color: #edfbd8;
    border: solid 1px #84d65a;
}

.success-icon path {
    fill: #84d65a;
}

.success-message {
    color: #2b641e;
}

/* ALERT */
.alert-popup {
    background-color: #fefce8;
    border: solid 1px #facc15;
}

.alert-icon path {
    fill: #facc15;
}

.alert-message {
    color: #ca8a04;
}

/* ERROR */
.error-popup {
    background-color: #fef2f2;
    border: solid 1px #f87171;
}

.error-icon path {
    fill: #f87171;
}

.error-message {
    color: #991b1b;
}

/* INFO */
.info-popup {
    background-color: #eff6ff;
    border: solid 1px #1d4ed8;
}

.info-icon path {
    fill: #1d4ed8;
}

.info-message {
    color: #1d4ed8;
}