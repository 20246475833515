.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
}

.card {
    width: 90%;
    max-width: 500px;
    min-width: 280px;
    height: auto;
    padding: 2rem;
    border-radius: 25px;
    background: #e0e0e0;
    box-shadow: 20px 20px 60px #bebebe,
        -20px -20px 60px #ffffff;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #315dc2;
    font-weight: bold;
}

.detail {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.detailName {
    color: #0D9A5C;
}

.detailValue {
    color: black;
}

.profit {
    color: green;
}

.loss {
    color: red;
}

@media (max-width: 600px) {
    .card {
        width: 95%;
        padding: 1.5rem;
    }

    .title {
        font-size: 1.3rem;
    }

    .detail {
        font-size: 0.9rem;
    }
}

.button {
    appearance: button;
    background-color: #1899D6;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .8px;
    line-height: 20px;
    margin: 20px auto 0;
    outline: none;
    overflow: visible;
    padding: 13px 19px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter .2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
}

.button:after {
    background-clip: padding-box;
    background-color: #1CB0F6;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.button:main,
.button:focus {
    user-select: auto;
}

.button:hover:not(:disabled) {
    filter: brightness(1.1);
}

.button:disabled {
    cursor: auto;
}

.button:active:after {
    border-width: 0 0 0px;
}

.button:active {
    padding-bottom: 10px;
}

.redButton {
    background-color: #ad1d1d;
}

.redButton:after {
    background-color: #F61C1C;
}

.greenButton {
    background-color: #1a9322;
}

.greenButton:after {
    background-color: #126717;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.modalContent h2 {
    color: black;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.modalContent p {
    color: black;
    margin-bottom: 20px;
}

.modalButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modalButtons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.modalButtons button:first-child {
    background-color: #ff4d4d;
    color: white;
}

.modalButtons button:first-child:hover {
    background-color: #ff3333;
}

.modalButtons button:last-child {
    background-color: #4CAF50;
    color: white;
}

.modalButtons button:last-child:hover {
    background-color: #45a049;
}