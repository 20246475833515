.tooltip {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    font-family: "Arial", sans-serif;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 11;
    bottom: 125%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #2d8fbd;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    z-index: 10;
}