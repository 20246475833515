.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 350px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-items: center;
  margin-top: 20px;
}

.formContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title {
  font-size: 28px;
  color: royalblue;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  justify-content: center;
}

.title::before,
.title::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 0px;
  background-color: royalblue;
  justify-content: center;
}

.title::before {
  width: 18px;
  height: 18px;
  background-color: royalblue;
  justify-content: center;
}

.title::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
  justify-content: center;
}

.message,
.signin {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.signin {
  text-align: center;
}

.signin a {
  color: royalblue;
}

.signin a:hover {
  text-decoration: underline royalblue;
}

.flex {
  display: flex;
  width: 100%;
  gap: 6px;
}

.form label {
  position: relative;
}

.form label .input {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
}

.form label .input+span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input:placeholder-shown+span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:focus+span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.form label .input:valid+span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
  color: grey;
}

.submit {
  border: none;
  outline: none;
  background-color: royalblue;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  transform: .3s ease;
}

.submit:hover {
  background-color: rgb(56, 90, 194);
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

.loader__btn {
  border: none;
  background-color: white;
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  border-radius: 12px;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: wait;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  border-radius: 50%;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  width: 23px;
  height: 23px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 62px;
  height: 35px;
}

.switch input {
  opacity: 1;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background: #5fdd54;
  transition: .4s;
  border-radius: 30px;
  border: 1px solid transparent;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.9em;
  width: 1.9em;
  border-radius: 16px;
  left: 1.2px;
  top: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 5px #999999;
  transition: .4s;
}

input:checked+.slider {
  background-color: #e81b1b;
}

input:checked+.slider:before {
  transform: translateX(1.5em);
}


.switchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchText {
  margin-right: 10px;
  margin-left: 10px;
  color: black;
  font-weight: bold;
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinner for Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  color: black;
  text-align: center;
}

.modal p,
.modal li {
  color: black;
}

.modal ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  text-align: left;
}

.modalButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.modalButtons button {
  padding: 12.5px 30px;
  border: 0;
  border-radius: 100px;
  background-color: #2ba8fb;
  color: #ffffff;
  font-weight: Bold;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.modalButtons button:hover {
  background-color: #6fc5ff;
  box-shadow: 0 0 20px #6fc5ff50;
  transform: scale(1.1);
}

.modalButtons button:active {
  background-color: #3d94cf;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  box-shadow: none;
  transform: scale(0.98);
}

.modalButtons button:first-child {
  background-color: #3b981c;
}

.modalButtons button:last-child {
  background-color: #fb2b2b;
}

.modalButtons button:last-child:hover {
  background-color: #ff6f6f;
  box-shadow: 0 0 20px #ff6f6f50;
}

.modalButtons button:last-child:active {
  background-color: #cf3d3d;
}

.warning {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}